import {observer} from "mobx-react";
import {useContext} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import {useHeroTerrace} from "../../hooks/useHeroTerrace";
import styles from "./styles/HeroContainer.module.scss";
import Logo from "./Logo";
import Markdown from "../utilities/Markdown";
import classNames from "classnames";

const LogoAndTitle = observer((props:{
    displayViewHomepageButton: boolean,
    handleViewHomepage: () => void,
    isWelcomePage: boolean,
    containerClassName?: string,
    logoClassName?: string,
    titleClassName?: string | null,
    displayViewHomepageButtonClassName?: string,
}) => {
    const {organizationStore, userStore, interfaceStore} = useContext(StoreContext);
    const heroBlock = useHeroTerrace();
    const canEditDescription = userStore.isAdmin && interfaceStore.isSchoolBlocks;

    const headerTextContainerClassName = classNames({
        [styles.headerTextContainer]: true,
    });

    const headerTextClassName = classNames({
        [styles.headerText]: true,
        [styles.headerTextEditable]: canEditDescription,
    });

    const heroTextClassName = classNames({
        [styles.heroText]: true,
        [props.containerClassName || '']: props.containerClassName,
    });

    const titleTextClassName = classNames({
        [styles.titleText]: true,
        [styles.titleTextTerrace]: !!heroBlock,
        [props.titleClassName||'']: !!props.titleClassName,
    });
    const descriptionItem = organizationStore.organization.json_data.settings?.headerText;
    const headerText = descriptionItem ? descriptionItem : (canEditDescription ? "Add Description" : null);
    const shouldHideTitle = (heroBlock && !props.isWelcomePage) ?
        heroBlock?.json_data?.hero?.hideTitle : organizationStore.organization.json_data.settings?.appearance?.hideWelcomePageTitle;
    const shouldHideLogo = (heroBlock && !props.isWelcomePage) ? heroBlock?.json_data?.hero?.hideLogo : organizationStore.organization.json_data.settings?.appearance?.hideLogo;
    return <div className={heroTextClassName}>
        {shouldHideLogo || <Logo logoClassName={props.logoClassName} />}
        {shouldHideTitle || <h2 className={titleTextClassName}>
            {organizationStore?.currentOrganization?.title}
        </h2>}
        {props.isWelcomePage && <div className={headerTextContainerClassName}>
            {headerText && <div className={headerTextClassName}>
                <div>
                    <Markdown source={headerText} withStyles={true}/>
                </div>
            </div>}
            {props.displayViewHomepageButton === true && <div>
                <button onClick={props.handleViewHomepage} className={`${styles.viewHomepageButton} ${props.displayViewHomepageButtonClassName}`}>View Homepage</button>
            </div>}
        </div>}
    </div>
});

export default LogoAndTitle;
